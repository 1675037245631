import React, { Component } from 'react';

import { Formik, Form, Field, ErrorMessage } from 'formik';
import questions from './questions'
import { RadioGroup, RadioButton } from 'react-radio-buttons'
import axios from 'axios'




export default class QuestionsForm extends Component {
  constructor(props) {
    super(props)
    this.state={
      questions,
      results: {},
      forgotName: false,
      name: ""
    }
  }

  updateScore = (q, value) => {
    q.score = value
    this.setState(questions)
    // this.state updates without this, but it is mutating base state
    // This causes weirdness when you try and look at this.state[any].value
  }


  validate = values => {
    let errors = {};
    let unAnswered = this.state.questions.find(o => o.score === 0 )

    if (!values.name) {
      errors.name = "Don't forget to fill out your name at the top";
      this.setState({forgotName: true})
    }
    if (unAnswered) {
      errors.q = "Looks like you missed a question"
    }
    return errors;
  }

  renderAnswerBoxes = question => {
    return (
      <div className="answer-box">
        <RadioGroup
          onChange={value => this.updateScore(question, value)}
          horizontal
        >
          <RadioButton value="-2" rootColor="#ddd" pointColor="#ea6671">
            Hell No
          </RadioButton>
          <RadioButton value="-1" rootColor="#ddd" pointColor="#ea6671">
            Not Really
          </RadioButton>
          <RadioButton value="0" rootColor="#ddd" pointColor="#ea6671">
            Meh
          </RadioButton>
          <RadioButton value="1" rootColor="#ddd" pointColor="#ea6671">
            Yup
          </RadioButton>
          <RadioButton value="2" rootColor="#ddd" pointColor="#ea6671">
            Hell Yes
          </RadioButton>
        </RadioGroup>
      </div>
    )
  }

  renderQuestions = () => {
    return questions.map(question => {
      return(
        <div className="question" key={question.body}>
          <div className="question-title">
            {question.body}
          </div>
          <div className="question-answer">
            {this.renderAnswerBoxes(question)}
          </div>
        </div>
      )
    })
  }

  calculateSuitability = () => {
    const tally = this.state.questions.reduce((final, i) => {
      i.score = parseInt(i.score) // Value comes through as a string. Convert to int
      // Setup keys
      if (!final[i.trait]) {
        final[i.trait] = {}
      }
      // check if the question was positively weighted
      if (i.positive) {
        final[i.trait].score = (final[i.trait].score || 0 ) + i.score
      } else {
        final[i.trait].score = (final[i.trait].score || 0 ) - i.score
      }

      // contribute to the aggregate so averages can be calculated
      final[i.trait].total = (final[i.trait].total || 0) + 1

      return final;
    }, {});

    // Reduce Tally to be an average
    let averages = Object.keys(tally).reduce((final, current) => {
      final[current] = tally[current].score / tally[current].total * 100 / 2
      return final
    }, {})

    this.setState({results: averages})
    this.props.updateData(averages)
  };

  storeResults = () => {
    const payload = {
      results: this.state.results,
      name: this.state.name,
      forgotName: this.state.forgotName
    }
    axios.post(`${this.props.submissionURI}/results`, payload)
  }


  onSubmit = (values, { setSubmitting }) => {
    const name = values.name
    setTimeout(() => {
      this.props.updateName({name})
      this.setState({name})
      this.calculateSuitability()
      this.storeResults()
      this.props.history.push('./results')
      setSubmitting(false)
    }, 400);
  }



  render() {
    return(
      <Formik
        initialValues={{ name: '', q: ''}}
        validate={this.validate}
        onSubmit={this.onSubmit}
        validateOnChange={false}
      >
        {({ isSubmitting }) => (
          <Form className="form">
            <div className="name-label">
              Enter your full name
            </div>
            <Field type="text" name="name" className="name-input"/>

            <div className="questions-container">
              {this.renderQuestions()}
            </div>

            <button type="submit" disabled={isSubmitting} className="submit-button" style={{color: isSubmitting? "#aaa": "fff"}}>
              {isSubmitting? "Hold Tight" : "Submit"}
            </button>
            <ErrorMessage name="name" component="div" className="error-messages"/>
            <ErrorMessage name="q" component="div" className="error-messages"/>
          </Form>
        )}
      </Formik>
    )
  }

}
