
import React, { Component } from 'react';
import {HorizontalBar, defaults} from 'react-chartjs-2';

defaults.global.defaultFontColor = "#fff"
defaults.global.defaultFontSize = 14
// console.log(defaults.global);

let chartLines = "#bbb"
let legendColor = "#fff"
let barBackground = (opacity) => `rgba(255, 255, 255, ${opacity.toString()})`
let barColor = barBackground(0.9)


const chartOptions = {
  legend: {
    display: true,
    labels: {
      fontColor: legendColor,
      fontSize: 16
    }
  },
  scales: {
    xAxes: [
      {
        gridLines: {
          display: true,
          color: chartLines,
        },
        ticks: {
          beginAtZero: true,
          fontColor: "#fff",
          max: 100,
          min: -100
        },
      }
    ],
    yAxes: [
      {
        gridLines: {
          display: true,
          color: chartLines,
        }
      }
    ]
  }
};


export default class Horizon extends Component {
  constructor(props) {
    super(props)
    this.state={data:{}}
  }

  componentDidMount() {
    let { i, c, e, a, n } = this.props.averages;
    this.setState({data: {
      labels: [
        "Intuitiveness",
        "Conscientiousness",
        "Extraversion",
        "Agreeableness",
        "Neuroticism"
      ],
      datasets: [
        {
          label: `${this.props.name.toUpperCase() || "Test".toUpperCase()}`,
          backgroundColor: barBackground(0.8),
          borderColor: barColor,
          borderWidth: 1,
          hoverBackgroundColor: barBackground(0.9),
          hoverBorderColor: barColor,
          data: [i, c, e, a, n]
        }
      ]
    }})
  }


  render() {
    return (
      <div>
        <h3 className="chart-title">Jet Dynamics Persona Spectrum Analysis</h3>
        <HorizontalBar data={this.state.data} width={1000} height={600} options={chartOptions}/>
      </div>
    );
  }
}
