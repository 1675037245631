import React, { Component } from 'react';

import Horizon from './horichart'


export default class Results extends Component {
  constructor(props) {
    super(props)
    if (!this.props.averages) {
      this.props.history.push('/')
    }
  }


  render() {
    return(
      <div className="horizon">
        {this.props.averages ? <Horizon averages={this.props.averages} name={this.props.name}/> : null}

      </div>
    )
  }
}
// {this.props.averages ? <Horizon averages={this.props.averages} name={this.props.name}/> : null}
//<Horizon averages={this.props.averages} name={this.props.name}/>
