import React, { Component } from 'react'
import axios from 'axios'

import Horizon from './horichart'


export default class EmailedResults extends Component {
  constructor(props) {
    super(props)
    this.state = {
      averages: {},
      name: ''
    }
  }


  async componentDidMount() {
    const applicant = await axios.get(`${this.props.serverURI}/pastResults/${this.props.match.params.id}`)
    console.log("RETURN", applicant.data.name);
    // I know name.name is stupid but its a bug in the form and i cbf fixing it.
    this.setState({
      averages: applicant.data.results,
      name: applicant.data.name,
      loaded: true
    })
  }


  render() {
    console.log("STAAATE m8", this.props);
    return(
      <div className="horizon">
        {this.state.loaded ? <Horizon averages={this.state.averages} name={this.state.name}/> : null}
      </div>
    )

  }
}
