import React, { Component } from 'react';
import './App.css';
import Bear from './bear.png'
import QuestionsForm from './Form'
import Results from './results'
import EmailedResults from './emailResults'
import Logo from './jlogo.png'


import { BrowserRouter as Router, Route } from "react-router-dom";


const serverURI = `https://persona-app-server.vercel.app/api`


class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      name: ''
      }
  }


  updateData = (averages) => {
    this.setState({averages})
  }

  updateName = (name) => {
    this.setState(name)
  }


  render() {
    console.log("APP STATE",this.state);
    return (

      <div className="App">
        <a href="/">
          <div className="logo">
            <img src={Logo} alt=""/>
          </div>
        </a>
        <Router>
          <div className="router">
            <Route exact path="/" render={(props) => <QuestionsForm {...props} updateData={this.updateData} updateName={this.updateName} submissionURI={serverURI}/>} />
            <Route path="/pastResults/:id" render={(props) => <EmailedResults {...props}  averages={this.state.averages} name={this.state.name} serverURI={serverURI}/> } />
            <Route exact path="/results" render={(props) => <Results {...props}  averages={this.state.averages} name={this.state.name}/>} />
          </div>
        </Router>

        <div className="bear">
          <img src={Bear} alt=""/>
        </div>
      </div>

    );
  }
}

export default App;
