
const questions = [
  {body: "I am the life of the party.", trait: "e", positive: true, score: 0},
  {body: "I feel little concern for others.", trait: "a", positive: false, score: 0},
  {body: "I am always prepared.", trait: "c", positive: true, score: 0},
  {body: "I get stressed out easily.", trait: "n", positive: true, score: 0},
  {body: "I have a rich vocabulary.", trait: "i", positive: true, score: 0},
  {body: "I don't talk a lot.", trait: "e", positive: false, score: 0},
  {body: "I am interested in people.", trait: "a", positive: true, score: 0},
  {body: "I leave my belongings around.", trait: "c", positive: false, score: 0},
  {body: "I am relaxed most of the time.", trait: "n", positive: false, score: 0},
  {body: "I have difficulty understanding abstract ideas.", trait: "i", positive: false, score: 0},
  {body: "I feel comfortable around people.", trait: "e", positive: true, score: 0},
  {body: "I insult people.", trait: "a", positive: false, score: 0},
  {body: "I pay attention to details.", trait: "c", positive: true, score: 0},
  {body: "I worry about things.", trait: "n", positive: true, score: 0},
  {body: "I have a vivid imagination.", trait: "i", positive: true, score: 0},
  {body: "I keep in the background.", trait: "e", positive: false, score: 0},
  {body: "I sympathize with others' feelings.", trait: "a", positive: true, score: 0},
  {body: "I make a mess of things.", trait: "c", positive: false, score: 0},
  {body: "I seldom feel blue.", trait: "n", positive: false, score: 0},
  {body: "I am not interested in abstract ideas.", trait: "i", positive: false, score: 0},
  {body: "I start conversations.", trait: "e", positive: true, score: 0},
  {body: "I am not interested in other people's problems.", trait: "a", positive: false, score: 0},
  {body: "I plan ahead", trait: "c", positive: true, score: 0},
  {body: "I get chores done right away.", trait: "c", positive: true, score: 0},
  {body: "I am easily disturbed or worried.", trait: "n", positive: true, score: 0},
  {body: "I have excellent ideas.", trait: "i", positive: true, score: 0},
  {body: "I have little to say.", trait: "e", positive: false, score: 0},
  {body: "I often forget to put things back in their proper place.", trait: "c", positive: true, score: 0},
  {body: "I get upset easily.", trait: "n", positive: true, score: 0},
  {body: "I talk to a lot of different people at parties.", trait: "e", positive: true, score: 0},
  {body: "I am not really interested in others.", trait: "a", positive: false, score: 0},
  {body: "I like order as opposed to chaos.", trait: "c", positive: true, score: 0},
  {body: "I change my mood a lot.", trait: "n", positive: true, score: 0},
  {body: "I am quick to understand things.", trait: "i", positive: true, score: 0},
  {body: "I don't like to draw attention to myself.", trait: "e", positive: true, score: 0},
  {body: "I take time out for others.", trait: "a", positive: true, score: 0},
  {body: "I like responsibility", trait: "c", positive: true, score: 0},
  {body: "My mood varies quite a bit sometimes", trait: "n", positive: true, score: 0},
  {body: "I use difficult words.", trait: "i", positive: true, score: 0},
  {body: "I don't mind being the center of attention.", trait: "e", positive: true, score: 0},
  {body: "I feel others' emotions.", trait: "a", positive: true, score: 0},
  {body: "I follow a schedule.", trait: "c", positive: true, score: 0},
  {body: "I get irritated easily.", trait: "n", positive: true, score: 0},
  {body: "I spend time reflecting on things.", trait: "i", positive: true, score: 0},
  {body: "I am quiet around strangers.", trait: "e", positive: false, score: 0},
  {body: "I am a perfectionist with my work", trait: "c", positive: true, score: 0},
  {body: "I often feel blue.", trait: "n", positive: true, score: 0},
  {body: "I am full of ideas.", trait: "i", positive: true, score: 0}
]

export default questions


/*
{body: "I am the life of the party.", trait: "e", positive: true, score: 0},
{body: "I feel little concern for others.", trait: "a", positive: false, score: 0},
{body: "I am always prepared.", trait: "c", positive: true, score: 0},
{body: "I get stressed out easily.", trait: "n", positive: true, score: 0},
{body: "I have a rich vocabulary.", trait: "i", positive: true, score: 0},
{body: "I don't talk a lot.", trait: "e", positive: false, score: 0},
{body: "I am interested in people.", trait: "a", positive: true, score: 0},
{body: "I leave my belongings around.", trait: "c", positive: false, score: 0},
{body: "I am relaxed most of the time.", trait: "n", positive: false, score: 0},
{body: "I have difficulty understanding abstract ideas.", trait: "i", positive: false, score: 0},
{body: "I feel comfortable around people.", trait: "e", positive: true, score: 0},
{body: "I insult people.", trait: "a", positive: false, score: 0},
{body: "I pay attention to details.", trait: "c", positive: true, score: 0},
{body: "I worry about things.", trait: "n", positive: true, score: 0},
{body: "I have a vivid imagination.", trait: "i", positive: true, score: 0},
{body: "I keep in the background.", trait: "e", positive: false, score: 0},
{body: "I sympathize with others' feelings.", trait: "a", positive: true, score: 0},
{body: "I make a mess of things.", trait: "c", positive: false, score: 0},
{body: "I seldom feel blue.", trait: "n", positive: false, score: 0},
{body: "I am not interested in abstract ideas.", trait: "i", positive: false, score: 0},
{body: "I start conversations.", trait: "e", positive: true, score: 0},
{body: "I am not interested in other people's problems.", trait: "a", positive: false, score: 0},
{body: "I plan ahead", trait: "c", positive: true, score: 0},
{body: "I get chores done right away.", trait: "c", positive: true, score: 0},
{body: "I am easily disturbed or worried.", trait: "n", positive: true, score: 0},
{body: "I have excellent ideas.", trait: "i", positive: true, score: 0},
{body: "I have little to say.", trait: "e", positive: false, score: 0},
{body: "I often forget to put things back in their proper place.", trait: "c", positive: true, score: 0},
{body: "I get upset easily.", trait: "n", positive: true, score: 0},
{body: "I talk to a lot of different people at parties.", trait: "e", positive: true, score: 0},
{body: "I am not really interested in others.", trait: "a", positive: false, score: 0},
{body: "I like order as opposed to chaos.", trait: "c", positive: true, score: 0},
{body: "I change my mood a lot.", trait: "n", positive: true, score: 0},
{body: "I am quick to understand things.", trait: "i", positive: true, score: 0},
{body: "I don't like to draw attention to myself.", trait: "e", positive: true, score: 0},
{body: "I take time out for others.", trait: "a", positive: true, score: 0},
{body: "I like responsibility", trait: "c", positive: true, score: 0},
{body: "My mood varies quite a bit sometimes", trait: "n", positive: true, score: 0},
{body: "I use difficult words.", trait: "i", positive: true, score: 0},
{body: "I don't mind being the center of attention.", trait: "e", positive: true, score: 0},
{body: "I feel others' emotions.", trait: "a", positive: true, score: 0},
{body: "I follow a schedule.", trait: "c", positive: true, score: 0},
{body: "I get irritated easily.", trait: "n", positive: true, score: 0},
{body: "I spend time reflecting on things.", trait: "i", positive: true, score: 0},
{body: "I am quiet around strangers.", trait: "e", positive: false, score: 0},
{body: "I am a perfectionist with my work", trait: "c", positive: true, score: 0},
{body: "I often feel blue.", trait: "n", positive: true, score: 0},
{body: "I am full of ideas.", trait: "i", positive: true, score: 0}
*/
